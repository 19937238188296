'use client'

import { memo, useCallback, useState, type CSSProperties } from 'react'
import OTPInput, { type OTPInputProps } from 'react-otp-input'

import { clsx } from '@sapphireui/core/utils'
import { Spacer } from '@sapphireui/react'

import type { StyleProps } from '~/lib/types'

type OtpInputProps = StyleProps &
	Omit<OTPInputProps, 'value' | 'onChange' | 'renderInput' | 'renderSeparator'> &
	Partial<{
		inputStyle: CSSProperties
		inputClassName: string

		initialValue: string
		onCodeFulfilled: (code: string) => unknown
	}>

const OtpInput = memo<OtpInputProps>(({ onCodeFulfilled, initialValue = '', numInputs = 6, ...props }) => {
	const [totp, setOtp] = useState<string>(initialValue)

	const handleFulFilled = useCallback(
		(code: string) => {
			if (code.length !== numInputs) {
				return
			}
			onCodeFulfilled?.(code)
		},
		[numInputs, onCodeFulfilled],
	)

	const handleChange = useCallback(
		(code: string) => {
			setOtp(code)
			handleFulFilled(code)
		},
		[handleFulFilled],
	)

	return (
		<OTPInput
			shouldAutoFocus
			skipDefaultStyles
			value={totp}
			onChange={handleChange}
			{...props}
			numInputs={numInputs}
			renderSeparator={<Spacer x={12} />}
			renderInput={(inputProps) => (
				<div className="relative h-16 w-12 rounded-[14px] focus-within:bg-gradient-to-r from-gradient-start to-gradient-end">
					<input
						{...inputProps}
						name="zip"
						inputMode="numeric"
						type="number"
						placeholder="-"
						className={clsx(
							'absolute inset-0.5 placeholder:text-text-muted',
							'bg-content2 rounded-[12px]',
							'text-center text-2xl',
							'focus:outline-none',
							'[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none',
						)}
					/>
				</div>
			)}
		/>
	)
})

export { OtpInput }
export type { OtpInputProps }
