'use client'

import { useContext } from 'react'

import { api } from '~/lib/trpc/client.query'

import { SessionContext } from './context'

const useSession = () => {
	const _ctx = useContext(SessionContext)

	if (!_ctx) {
		throw new Error('`useSession` can not be used outside of `SessionContext`')
	}

	const { data: sessions } = api.session.getAll.useQuery(undefined)

	return {
		sessions,
		user: _ctx.user,
	}
}

export { useSession }
