import { DOCS_URL } from '~/lib/env'

type PageConfig = {
	id: string
	name: string
	href: string | ((...args: any[]) => string)

	external?: boolean
}

const internalPageConfig = {
	sign: {
		id: 'sign',
		href: '/sign',
		name: 'Авторизация',
	},

	home: {
		id: 'index',
		href: '/',
		name: 'Главная',
	},

	map: {
		id: 'map',
		href: '/map',
		name: 'Рабочая карта',
	},

	history: {
		id: 'history',
		href: '/history',
		name: 'История',
	},

	settings: {
		id: 'settings',
		href: '/settings',
		name: 'Настройки',
	},
	account: {
		id: 'account',
		name: 'Аккаунт',
		href: '/settings/account',
	},
	billing: {
		id: 'billing',
		name: 'Оплата',
		href: '/settings/billing',
	},
	device: {
		id: 'device',
		name: 'Устройства',
		href: '/settings/device',
	},
	new: {
		id: 'new',
		href: '/transfer/new',
		name: 'Создание',
	},

	shared: {
		id: 'shared',
		href: (id: string | number) => `/shared/${id}`,
		name: 'Поделиться',
	},

	transfer: {
		id: 'transfer',
		href: (id: string | number) => `/transfer/${id}`,
		name: 'Трансфер',
	},

	edit: {
		id: 'edit',
		href: (id: string | number) => `/transfer/${id}/edit`,
		name: 'Редактирование',
	},
} satisfies Record<string, PageConfig>

const externalPageConfig = {
	index: {
		id: '/',
		name: 'Главная',
		href: new URL('/', DOCS_URL).toString(),
		external: false,
	},
	posts: {
		id: 'posts',
		name: 'Новости',
		href: new URL('/posts', DOCS_URL).toString(),
		external: true,
	},
	contact: {
		id: 'contact',
		name: 'Контакты',
		href: new URL('/contact', DOCS_URL).toString(),
		external: true,
	},

	/**
	 * @description faq contains `legal`, `hints` and `tutorial`
	 */
	faq: {
		id: 'faq',
		name: 'FAQ',
		external: true,
		href: new URL('/faq', DOCS_URL).toString(),
	},
	/**
	 * @description faq content
	 */
	legal: {
		id: 'legal',
		name: 'Документы',
		href: new URL('/faq/legal', DOCS_URL).toString(),
		external: true,
	},
	hints: {
		id: 'hints',
		name: 'Подсказки',
		href: new URL('/faq/hints', DOCS_URL).toString(),
		external: true,
	},
	tutorial: {
		id: 'tutorial',
		name: 'Инструкции',
		href: new URL('/faq/tutorial', DOCS_URL).toString(),
		external: true,
	},

	/**
	 * @description legal
	 */
	userAgreement: {
		id: 'user-agreement',
		name: 'Пользовательское соглашение',
		href: new URL('/user-agreement.pdf', DOCS_URL).toString(),
		external: true,
	},
	privacyPolicy: {
		id: 'privacy-policy',
		name: 'Политика конфиденциальности',
		href: new URL('/privacy-policy.pdf', DOCS_URL).toString(),
		external: true,
	},
	paymentMethods: {
		id: 'payment-methods',
		name: 'Информация о способах оплаты',
		href: new URL('/payment-methods.pdf', DOCS_URL).toString(),
		external: true,
	},
	privacyPolicyProccesing: {
		id: 'privacy-policy-proccesing',
		name: 'Политика обработки персональных данных',
		href: new URL('/privacy-policy.pdf', DOCS_URL).toString(),
		external: true,
	},
	privacyPolicyAgreement: {
		id: 'privacy-policy-agreement',
		name: 'Согласие на обработку персональных данных',
		href: new URL('/privacy-policy.pdf', DOCS_URL).toString(),
		external: true,
	},
	offerAgreement: {
		id: 'offer-agreement',
		name: 'Публичный договор',
		href: new URL('/offer-agreement.pdf', DOCS_URL).toString(),
		external: true,
	},
} satisfies Record<string, PageConfig>

export { internalPageConfig, externalPageConfig }
export type { PageConfig }
