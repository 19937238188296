'use client'

import { useState, useEffect, useCallback } from 'react'

type UseTimerProps = {
	seconds: number
}

const useTimer = ({ seconds }: UseTimerProps) => {
	const [timeLeft, setTimeLeft] = useState<number>(seconds)

	const reset = useCallback(() => {
		setTimeLeft(seconds)
	}, [seconds])

	useEffect(() => {
		// exit early when we reach 0
		if (!timeLeft) {
			return
		}

		// save intervalId to clear the interval when the
		// component re-renders
		const intervalId = setInterval(() => {
			setTimeLeft(timeLeft - 1)
		}, 1000)

		// clear interval on re-render to avoid memory leaks
		return () => clearInterval(intervalId)
		// add timeLeft as a dependency to re-rerun the effect
		// when we update it
	}, [timeLeft])

	return { timeLeft, isFinished: timeLeft <= 0, reset }
}

type UseTimerReturn = ReturnType<typeof useTimer>

export { useTimer }
export type { UseTimerProps, UseTimerReturn }
