'use client'

import { useCallback } from 'react'

import { Input, Button } from '@sapphireui/react'

import { Icon } from '@seeurcargo/icons'

import { useAuth } from '~/lib/auth/client'
import type { FormEventWithValues } from '~/lib/types'

import { ConfirmPrivacyText } from './ConfirmPrivacyText'

type EmailStepProps = {
	setUserEmailSended: (value: string) => unknown
}

const EmailStep = ({ setUserEmailSended }: EmailStepProps) => {
	const { send } = useAuth()

	const handleSubmit = useCallback(
		async (event: FormEventWithValues<{ email: string }>) => {
			event.preventDefault()
			const email = event.currentTarget.email.value

			return send
				.mutateAsync({ email })
				.then((data) => setUserEmailSended(data.email))
		},
		[send, setUserEmailSended],
	)

	return (
		<form
			onSubmit={handleSubmit}
			className="w-full flex flex-col gap-y-4"
		>
			<Input
				full
				required
				size="xl"
				name="email"
				type="email"
				variant="solid"
				className="pr-4"
				placeholder="example@yandex.by"
				label="Введите электронную почту"
				readOnly={send.isPending}
				iconRight={
					<Icon
						size={24}
						name="chevron-right"
						className="animate-bounce"
					/>
				}
			/>

			<ConfirmPrivacyText />

			<div className="self-end mt-4">
				<Button
					gradient
					type="submit"
					loading={send.isPending}
				>
					Далее
				</Button>
			</div>
		</form>
	)
}

export { EmailStep }
export type { EmailStepProps }
