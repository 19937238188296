import { useCallback } from 'react'
import { useDebounce } from 'use-debounce'
import { useSearchParams } from 'next/navigation'

type UseQueryStateOpts<V> = Partial<{
	replace?: boolean
	debounce?: number | null | undefined

	defaultValue?: V | null | undefined
}>

const useQueryState = <V>(
	key: string,
	{ replace, debounce, defaultValue }: UseQueryStateOpts<V> = {},
) => {
	const searchParams = useSearchParams()
	const state = searchParams.get(key) || defaultValue

	const setState = useCallback(
		(arg: V) => {
			const params = new URLSearchParams(searchParams.toString())
			arg ? params.set(key, String(arg)) : params.delete(key)

			const url = `?${params.toString()}`
			const fn = replace ? window.history.replaceState : window.history.pushState

			fn(null, '', url)
		},
		[key, replace, searchParams],
	)

	const [debouncedState] = useDebounce(state, debounce || 200)

	return [debouncedState, setState] as const
}

type UseQueryStateResult = ReturnType<typeof useQueryState>

export { useQueryState }
export type { UseQueryStateOpts, UseQueryStateResult }
