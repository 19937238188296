'use client'

import { createContext } from 'react'

import { api } from '~/lib/trpc/client.query'
import type { Provider } from '~/lib/types'

import type { User } from '~/shared/entities'

type SessionConfig = {
	user: User
}

const SessionContext = createContext<SessionConfig>({} as SessionConfig)

const SessionProvider: Provider<SessionConfig> = ({ user, children }) => {
	const userQuery = api.auth.me.useQuery(undefined, { initialData: user })

	return (
		<SessionContext.Provider value={{ user: userQuery.data }}>
			{children}
		</SessionContext.Provider>
	)
}

export { SessionContext, SessionProvider }
export type { SessionConfig }
