import { Title, Button, Spacer } from '@sapphireui/react'

import { Icon } from '@seeurcargo/icons'

import type { User } from '~/shared/entities'
import { pageConfig } from '~/shared/config/page'

import { UserCard } from '~/client/components/(cards)/UserCard'

type UserConfirmStepProps = {
	user: User
	reset: () => unknown
}

const UserConfirmStep = ({ user, reset }: UserConfirmStepProps) => (
	<form
		className="w-full mx-auto max-w-xs"
		onSubmit={() => window.history.pushState(undefined, '', pageConfig.home.href)}
	>
		<Title>Ваш профиль</Title>

		<Spacer y={16} />

		<UserCard
			className="h-20"
			email={user.email}
			username={user.username}
			avatarProps={{ src: user.avatarUrl }}
		/>

		<Spacer y={20} />

		<Button
			full
			gradient
			type="submit"
			iconRight={<Icon name="external-link-solid" />}
		>
			Продолжить
		</Button>
	</form>
)

export { UserConfirmStep }
