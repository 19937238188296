import { memo } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Hint, Text, Avatar, type AvatarProps } from '@sapphireui/react'

import type { StyleProps } from '~/lib/types'

import { Card, type CardProps } from './Card'

type UserCardProps = StyleProps &
	Omit<CardProps, 'label'> & {
		email: string

		username?: string | null | undefined

		avatarProps?: AvatarProps
	}

const UserCard = memo<UserCardProps>(({ email, username, className, avatarProps, ...props }) => (
	<Card
		{...props}
		label="Создан"
		className={clsx('flex flex-row items-center text-sm', className)}
	>
		<Avatar
			rounded
			{...avatarProps}
			text={username || email}
			className={clsx('flex-none h-11 w-11 bg-overlay', avatarProps?.className)}
		/>

		<div className="flex-1 h-12 py-1 flex flex-col justify-center gap-y-1 leading-none">
			<Text
				truncated
				weight={500}
			>
				{username}
			</Text>
			<Hint truncated>{email}</Hint>
		</div>
	</Card>
))

export { UserCard }
export type { UserCardProps }
