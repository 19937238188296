'use client'

import { toast } from 'sonner'
import { useCallback } from 'react'

import { api } from '~/lib/trpc/client.query'

import { pageConfig } from '~/shared/config/page'

const useUser = () => {
	const onUnauth = useCallback(() => {
		window.history.pushState(undefined, '', pageConfig.sign.href)
	}, [])

	const editMutation = api.user.editOne.useMutation({
		onSuccess: () => {
			toast.success('Изменения были внесены успешно!')
		},
	})
	const deleteMutation = api.user.deleteOne.useMutation({ onSuccess: onUnauth })

	return { edit: editMutation, remove: deleteMutation }
}

export { useUser }
