import { Fragment } from 'react'

import { clsx } from '@sapphireui/core/utils'
import { Hint, type TextProps } from '@sapphireui/react'

import { TextLink } from '@seeurcargo/shared'

import { externalPageConfig } from '~/shared/config/page_test'

const ConfirmPrivacyText = ({ className, ...props }: TextProps) => {
	const legalLinks = [externalPageConfig.privacyPolicy, externalPageConfig.userAgreement]

	const legalContent = legalLinks.map((legal, idx) => (
		<Fragment key={legal.id}>
			<TextLink
				underlined
				href={legal.href}
				className="inline-block px-0"
			>
				{legal.name}
			</TextLink>
			{idx < legalLinks.length - 1 ? <span> и </span> : null}
		</Fragment>
	))

	return (
		<Hint
			{...props}
			className={clsx('text-left text-xs sm leading-6', className)}
		>
			Нажимая Далее вы подтверждаете, что ознакомлены с: {legalContent}.
		</Hint>
	)
}

export { ConfirmPrivacyText }
