/**
 * @description loaded from .env
 * */

export const BETA = process.env.BETA === 'true' ? true : false
export const NODE_ENV = process.env.NODE_ENV as string
export const PROJECT_NAME = process.env.PROJECT_NAME || 'SeeUrCargo'

export const DOCS_URL = process.env.DOCS_URL || 'https://seeurcargo.com'

export const DOMAIN = (process.env.DOMAIN || process.env.NEXT_PUBLIC_DOMAIN) as string
export const DOMAIN_URL = process.env.VERCEL_URL || `https://${DOMAIN}`

export const MAP_DOMAIN = (process.env.MAP_DOMAIN || process.env.NEXT_PUBLIC_MAP_DOMAIN) as string
export const MAP_STYLE_URL = (process.env.MAP_STYLE_URL ||
	process.env.NEXT_PUBLIC_MAP_STYLE_URL) as string

export const EDGE_STORE_ACCESS_KEY = process.env.EDGE_STORE_ACCESS_KEY as string
export const EDGE_STORE_SECRET_KEY = process.env.EDGE_STORE_SECRET_KEY as string

export const DEV = NODE_ENV === 'development'
export const PROD = !DEV
