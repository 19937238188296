import type { Metadata } from 'next'
import { Icon } from '@seeurcargo/icons'

import type { ReactChildren } from '~/lib/types'

const generateTitle = (title: string) => `${title} • SeeUrCargo`

type PageConfig = {
	id: string
	href: string | ((...arg: any[]) => string)
	name: string
	external?: boolean

	icon?: ReactChildren
	icons?: {
		active: ReactChildren
		unactive: ReactChildren
	}

	metadata?: Metadata
	headerShown?: boolean
}

const pageConfig = {
	sign: {
		id: 'sign',
		href: '/sign',
		name: 'Авторизация',
		metadata: {
			title: generateTitle('Авторизация'),
		},
		headerShown: true,
	},

	home: {
		id: 'index',
		href: '/',
		name: 'Главная',
		icons: {
			active: <Icon name="home" />,
			unactive: <Icon name="home" />,
		},
		metadata: {
			title: generateTitle('Главная'),
		},
		headerShown: true,
	},

	map: {
		id: 'map',
		href: '/map',
		name: 'Рабочая карта',
		icons: {
			active: <Icon name="map" />,
			unactive: <Icon name="map" />,
		},
		metadata: {
			title: generateTitle('Рабочая карта'),
		},
		headerShown: true,
	},

	history: {
		id: 'history',
		href: '/history',
		name: 'История',
		icon: <Icon name="history" />,
		metadata: {
			title: generateTitle('История активности'),
		},
		headerShown: true,
	},

	settings: {
		id: 'settings',
		href: '/settings',
		name: 'Настройки',
		icons: {
			active: <Icon name="settings" />,
			unactive: <Icon name="settings" />,
		},
		metadata: {
			title: generateTitle('Настройки'),
		},
		headerShown: false,
	},
	account: {
		id: 'account',
		name: 'Аккаунт',
		href: '/settings/account',
		icons: {
			active: <Icon name="user" />,
			unactive: <Icon name="user" />,
		},
		metadata: {
			title: generateTitle('Аккаунт - Настройки'),
		},
		headerShown: false,
	},
	billing: {
		id: 'billing',
		name: 'Оплата',
		href: '/settings/billing',
		icons: {
			active: <Icon name="billing" />,
			unactive: <Icon name="billing" />,
		},
		metadata: {
			title: generateTitle('Оплата - Настройки'),
		},
		headerShown: false,
	},
	device: {
		id: 'device',
		name: 'Устройства',
		href: '/settings/device',
		icons: {
			active: <Icon name="device" />,
			unactive: <Icon name="device-outline" />,
		},
		metadata: {
			title: generateTitle('Устройства - Настройки'),
		},
		headerShown: false,
	},
	new: {
		id: 'new',
		href: '/transfer/new',
		name: 'Создание',
		metadata: {
			title: generateTitle('Создание'),
		},
		headerShown: false,
	},
	shared: {
		id: 'shared',
		href: (id: string | number) => `/shared/${id}`,
		name: 'Поделиться',
	},
	transfer: {
		id: 'transfer',
		href: (id: string | number) => `/transfer/${id}`,
		name: 'Трансфер',
		headerShown: false,
	},

	edit: {
		id: 'edit',
		href: (id: string | number) => `/transfer/${id}/edit`,
		name: 'Редактирование',
		headerShown: false,
	},

	support: {
		id: 'support',
		href: '/support',
		name: 'Поддержка',
		external: true,
		icon: <Icon name="external-link" />,
		metadata: {
			title: generateTitle('Поддержка'),
		},
	},
	feedback: {
		id: 'feedback',
		href: '/feedback',
		name: 'Обратная связь',
		external: true,
		icon: <Icon name="feedback" />,
		metadata: {
			title: generateTitle('Обратная связь'),
		},
	},
	faq: {
		id: 'faq',
		href: '/faq',
		name: 'Вопрос & Ответ',
		external: true,
		icon: <Icon name="faq" />,
		metadata: {
			title: generateTitle('Вопрос & Ответ'),
		},
	},

	rules: { id: 'rules', href: '/home', name: 'Правила сайта' },
	privacy: { id: 'privacy', href: '/home', name: 'Политика конфиденциальности' },
} satisfies Record<string, PageConfig>

export { pageConfig, generateTitle }
export type { PageConfig }
