import { memo } from 'react'

import { Text } from '@sapphireui/react'
import { clsx } from '@sapphireui/core/utils'

import type { StyleProps, ChildrenProp } from '~/lib/types'

type CardProps = StyleProps & {
	label?: string | null | undefined
	withLabel?: boolean

	transparent?: boolean
}

const Card = memo<CardProps & ChildrenProp>(
	({ style, label, className, children, withLabel = false, transparent = false }) => (
		<article className="w-full">
			{label && withLabel ? (
				<Text className="mb-3 text-xs uppercase text-text-ghost font-medium">
					{label}
				</Text>
			) : null}
			<div
				style={style}
				className={clsx(
					'relative w-full flex flex-col gap-3',
					transparent ? '' : 'bg-content2 rounded-md p-5',
					className,
				)}
			>
				{children}
			</div>
		</article>
	),
)

export { Card }
export type { CardProps }
