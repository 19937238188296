'use client'

import { useCallback } from 'react'

import { Icon } from '@seeurcargo/icons'
import { ContactLink } from '@seeurcargo/shared'

import { clsx } from '@sapphireui/core/utils'
import { Text, Title, Button, type ButtonProps, Spacer } from '@sapphireui/react'

import { useAuth } from '~/lib/auth/client'
import type { User } from '~/shared/entities'

import { useTimer } from '~/client/hooks/useTimer'

import { OtpInput } from '~/client/components/(inputs)/OtpInput'

type ConfirmOtpStepProps = {
	email: string
	setUser: (user: User) => unknown
	reset: () => unknown
}

const ConfimOtpStep = ({ email, setUser, reset: resetForm }: ConfirmOtpStepProps) => {
	const { send, check } = useAuth()
	const { timeLeft, reset: resetTimer } = useTimer({ seconds: 45 })

	const resend = useCallback(() => {
		send.mutateAsync({ email }).then(resetTimer)
	}, [send, email, resetTimer])

	const handleSubmit = useCallback(
		(code: string) =>
			check.mutateAsync({ email, totp: code }).then((data) => {
				resetTimer()
				setUser(data.user)
			}),
		[check, setUser, resetTimer, email],
	)

	return (
		<div className="mx-auto w-fit flex flex-col items-center text-center">
			<Text
				onClick={resetForm}
				className="self-end flex items-center gap-x-1 select-none text-primary hover:brightness-75 focus:brightness-75 cursor-pointer transition-all"
			>
				<Icon name="chevron-left" />
				<span>Эл. почта</span>
			</Text>

			<Spacer y={16} />

			<Title align="center">Введите код подтверждения</Title>

			<Spacer y={16} />

			<div className="flex-center flex-col font-normal text-sm gap-y-1">
				<Text>Мы отправили код на Ваш электронный ящик</Text>
				<ContactLink
					type="email"
					text={email}
					className="text-primary hover:brightness-75 focus:brightness-75 transition-all"
				/>
			</div>

			<Spacer y={24} />

			<OtpInput onCodeFulfilled={handleSubmit} />

			<Spacer y={36} />

			<div className="h-16 w-full max-w-xs">
				<ResendButton
					full
					onClick={resend}
					secondsLeft={timeLeft}
					loading={send.isPending}
					timerText="Если код не пришел, получить новый можно"
				>
					Отправить повторно
				</ResendButton>
			</div>
		</div>
	)
}

type ResendButtonProps = ButtonProps & {
	timerText: string
	secondsLeft?: number
}

const ResendButton = ({
	style,
	className,
	timerText,
	secondsLeft = 0,
	...props
}: ResendButtonProps) => {
	if (secondsLeft <= 0) {
		return (
			<Button
				style={style}
				className={className}
				{...props}
			>
				Отправить повторно
			</Button>
		)
	}

	return (
		<Text
			style={style}
			className={clsx('text-center text-text-muted text-sm', className)}
		>
			Не получили код? Через {secondsLeft} секунд отправьте повторно
		</Text>
	)
}

export { ConfimOtpStep }
