'use client'

import { toast } from 'sonner'
import { useRouter } from 'next/navigation'

import { api } from '~/lib/trpc/client.query'

import { pageConfig } from '~/shared/config/page'

const useAuth = () => {
	const router = useRouter()

	const user = api.auth.me.useQuery()

	const sendOtpMutation = api.auth.sendOtp.useMutation({
		onSuccess: () => toast.success('Код подтверждения отправлен вам на почту!'),
		onError: (error) => {
			if (error.data?.code === 'TOO_MANY_REQUESTS') {
				toast.error('Слишком много запросов на данную электронную почту! Подождите 1 минуту.')
			} else {
				toast.error('Введен неверный адрес электронной почты')
			}
		},
	})

	const checkOtpMutation = api.auth.verifyOtp.useMutation({
		onSuccess: () => toast.success('Вы успешно вошли!'),
		onError: (error) => {
			if (error.data?.code === 'TOO_MANY_REQUESTS') {
				toast.error('Слишком много запросов! Подождите 5 секунд.')
			}
			if (error.data?.code === 'PRECONDITION_FAILED') {
				toast.error('Не указан адрес эл. почты')
			} else {
				toast.error('Вы ввели неверный код')
			}
		},
	})

	const signOutMutation = api.auth.signOut.useMutation({
		onSuccess: () => {
			router.push(pageConfig.sign.href)
			toast.info('Вы вышли из учетной записи')
			router.refresh()
		},
	})
	const signOutOthersMutation = api.session.removeOthers.useMutation({
		onSuccess: () => toast.success('Вы успешно завершили все сессии, кроме текущий!'),
	})

	return {
		user,
		send: sendOtpMutation,
		check: checkOtpMutation,
		signOut: signOutMutation,
		signOutOthers: signOutOthersMutation,
	}
}

export { useAuth }
