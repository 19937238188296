'use client'

import { useMemo, useState, useCallback } from 'react'

import { clsx } from '@sapphireui/core/utils'

import type { User } from '~/shared/entities'
import type { StyleProps } from '~/lib/types'

import { useQueryState } from '~/client/hooks/useQueryState'

import { EmailStep } from './_steps/EmailStep'
import { ConfimOtpStep } from './_steps/ConfimOtpStep'
import { UserConfirmStep } from './_steps/UserConfirmStep'

type SignFormProps = {
	initialUser?: User
	initialUserEmail?: string
}

const SignForm = ({ style, className, initialUser, initialUserEmail }: StyleProps & SignFormProps) => {
	const [userEmail, setUserEmail] = useQueryState<string | undefined | null>('email', {
		defaultValue: initialUserEmail,
	})

	const [user, setUser] = useState<User | undefined | null>(initialUser)

	const reset = useCallback(() => {
		setUser(undefined)
		setUserEmail('')
	}, [setUser, setUserEmail])

	const step = useMemo(() => {
		if (user) {
			return (
				<UserConfirmStep
					user={user}
					reset={reset}
				/>
			)
		}

		if (!!userEmail) {
			return (
				<ConfimOtpStep
					reset={reset}
					setUser={setUser}
					email={userEmail}
				/>
			)
		}

		return <EmailStep setUserEmailSended={setUserEmail} />
	}, [user, reset, setUser, setUserEmail, userEmail])

	return (
		<div
			style={style}
			className={clsx('w-full flex flex-col gap-y-4', className)}
		>
			{step}
		</div>
	)
}

export { SignForm }
export type { SignFormProps }
